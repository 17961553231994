<template>
  <div class="content">
    <div class="subtitle">
      <h1>blog</h1>
      <p>6th of March 2022</p>
    </div>
    <div class="title">
      <h2>Unhelpul Thoughts by Jo Dalziel</h2>
    </div>
    <img class="image" src="/src/assets/images/bellebirthfertility_logo.png" alt="Belle Birth Fertility" />
    <div class="body-copy">
      <p>I loved this blog so much I asked Jo if I could feature it as a guest blog.</p>
      <p><a href="https://jodalziel.co.uk/unhelpful-thinking/" target="_blank">jodalziel.co.uk/unhelpful-thinking</a></p>
      <p>To find out more about the birth and fertility mentoring Jo provides, please visit her website <a href="https://jodalziel.co.uk">jodalziel.co.uk</a>.</p>
    </div>
  </div>
</template>

<script>
import sleepImg from '../assets/images/bellebirthfertility_logo.png';

export default {
  data () {
    return {}
  }
}
</script>

<style scoped lang="less">
@import '../assets/css/less/responsive.less';

.content {
  > .subtitle {
    grid-column: 2/8;
    grid-row: 1/2;
    .screen-xs-max({
      grid-column: 1/9;
    });
    > h1 {
      margin: 0;
    }
    > p {
      margin-bottom: 0;
    }
  }
  > .title {
    grid-column: 2/8;
    grid-row: 2/3;
    .screen-xs-max({
      grid-column: 1/9;
      grid-row: 3/4;
    });
    > h2 {
      font-size: 2.2em;
    }
  }
  > .image {
    grid-row: 3/5;
    object-fit: contain;
    object-position: top;
    .screen-xs-max({
      grid-row: 2/3;
    });
  }
  > .body-copy {
    grid-column: 2/5;
    grid-row: 3/4;
    .screen-xs-max({
      grid-column: 1/9;
      grid-row: 4/5;
    });
  }
}
</style>
