<template>
  <div class="code-of-ethics content">
    <div class="title">
      <h3>code of ethics</h3>
    </div>
    <div class="body-copy">
      <p>Employees of glow reflexology shall at all times:</p>
      <ol>
        <li>Act in the best interests of clients and treat them with respect</li>
        <li>Maintain client confidentiality</li>
        <li>Take responsibility for their own actions</li>
        <li>
          Maintain high standards of personal conduct
          <ol type="a">
            <li>Clear boundaries should be maintained when treating a relative or friend</li>
            <li>Staff members should not become emotionally involved with a client</li>
          </ol>
        </li>
        <li>
          Practice only within the limits of their competence, referring onto other complementary therapists or health professional where necessary
          <ol type="a">
            <li>Respect and maintain good relationships with other practitioners and health professionals</li>
            <li>Not diagnose medical disorders though diagnoses of reflexes that are out of balance is acceptable</li>
          </ol>
        </li>
        <li>
          Maintain and develop your knowledge and skills
          <ol type="a">
            <li>Keep up with the Federation of Holistic Therapists (FHT) and Association of Reflexologists (AoR) standards of Continuing Professional Development each year</li>
          </ol>
        </li>
        <li>Observe high standards of performance</li>
        <li>Protect their reputation</li>
        <li>Practise within the law, both personally and professionally.  Be aware of the rules of advertising in making no claims for curing of disorders or claims of results of treatment, as set down by the British Advertising Code of Practice</li>
        <li>Maintain appropriate communications with clients, users, carers and other professionals</li>
        <li>
          Obtain informed consent to give treatments
          <ol type="a">
            <li>Recognise that a contra-action may mean it’s not possible to go ahead with a treatment</li>
            <li>Encourage GP consent when clients have certain health conditions</li>
          </ol>
        </li>
        <li>Keep in good physical, emotional and mental health.  Take action if your physical, mental or emotional health is affected which could affect your fitness to practice</li>
        <li>
          Carry out duties and responsibilities in a professional and ethical way
          <ol type="a">
            <li>Be covered by public liability and professional malpractice insurance</li>
            <li>Do not solicit patients from another practitioner in the same locality</li>
          </ol>
        </li>
        <li>Employees shall respect all persons, irrespective of ethnicity, age, disability, gender, race, religion, sexual orientation, and must never seek to impose their beliefs on a client</li>
        <li>Employees should maintain accurate records of each treatment which must be stored securely</li>
      </ol>
      <h3>References</h3>
      <ul>
        <li>Association of Reflexologists 2013</li>
        <li>The British Reflexology Association - code of conduct, practice and ethics</li>
        <li>Clinical Association of Reflexologists - code of practice and ethics</li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {}
  }
}
</script>

<style scoped lang="less">
@import '../assets/css/less/responsive.less';

.content {
  > .title {
    grid-column: 2/8;
    .screen-xs-max({
      grid-column: 1/9;
    });
  }
  > .body-copy {
    grid-column: 2/8;
    .screen-xs-max({
      grid-column: 1/9;
    });
  }
}
</style>
