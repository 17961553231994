<template>
  <div class="content">
    <div class="subtitle">
      <h1>blog</h1>
      <p>8th of June 2022</p>
    </div>
    <div class="title">
      <h2>understanding your cycle with temperature charting</h2>
    </div>
    <h3>When trying to conceive there are a number of tools available to you to help you understand if and when you are ovulating and when your "fertile window" is. They all have their pros and cons, but today I wanted to write about my favourite - basal body temperature charting.</h3>
    <div class="image-container">
      <img class="image" src="/src/assets/images/temperature-charting.jpg" alt="Temperature charting" />
    </div>
    <div class="body-copy">
      <p>Basal body temperature charting involves taking your temperature orally every morning upon waking, strictly before doing anything else and after three hours of unbroken sleep. There are many different ways of recording these temperatures, but the one I prefer and recommend to my clients is called <a href="https://www.fertilityfriend.com" target="_blank">Fertility Friend</a>. It's a free app or you can use the website, and once you are all set up it's very user friendly. You can chart as much detail as you like: information about your period, mood, cervical mucus, energy levels, exercise, drinking, any medications taken, as sometimes these can impact your temperature or be impacted by where you are at in your cycle.</p>
      <p>Temperature charting can give you a good indication of whether ovulation has taken place.  At ovulation, you see a sharp increase in temperature, and this should stay elevated for the second part of your cycle (the luteal phase).</p>
      <p>I have included a couple of examples in this post (with my lovely client's permission). Fertility Friend has included a dotted line where it "thinks" ovulation has taken place.</p>
      <p><img src="/src/assets/images/fertility-friend-chart-1.jpg" alt="temperature chart 1"></p>
      <p>Temperature charting can help to highlight issues with a woman’s cycle.  It may highlight that there is no clear ovulation, that a luteal phase is too short to sustain a pregnancy or temperatures are dropping too soon before a period (potentially indicating an issue with progesterone).</p>
      <p>The example above shows a lovely long luteal phase, long enough to sustain a pregnancy. The  high temps after ovulation fluctuate a bit indicating that there could be a little instability in her progesterone levels, but overall they remain higher so I wouldn't be too concerned. In this example, you will see that some triangles are filled and some are empty - for this client it indicates that she hasn't taken her temperature at the same time every day. This is something that is impossible for her due to having to get up at different times for work. Because of this we do need to be cautious in our interpretation, and fertility friend has included a broken axis indicating that the algorithm is uncertain. But as long as her temperature is taken after three hours of unbroken sleep then they should be fairly reliable.</p>
      <p>Often women think they have a good cycle, regular as clockwork, but when they start charting their temperature, it becomes clear that they are not ovulating every time. Ovulation Predictor Kits are sometimes misleading because while they can detect the LH surge, this doesn't always mean that ovulation will happen. In an earlier example below (same client), you will see that ovulation isn't clear and her cycle was much shorter than her norm.</p>
      <p><img src="/src/assets/images/fertility-friend-chart-2.jpg" alt="temperature chart 2"></p>
      <p>Another reason I like temperature charting is that as a reproductive reflexologist, I can work with a woman for a few cycles and we can observe changes and note improvements, it gives us an evidence base. It also allows me to tailor my treatment to where she is in her cycle, with an aim to improve and regulate it.</p>
      <p>That said, temperature charting is not always easy. To get an accurate reading, temperatures really need to be taken after three hours of unbroken sleep. Most of those who are trying for baby #2+ would find this tricky! There is an option in this case to use Ovusence (a device that is inserted vaginally as you sleep), more pricey - but the data and support gained from this is very accurate.</p>
      <p>Another drawback is you are looking for ovulation retrospectively so it's not a predictor, but it can give you an idea of when it is likely to happen going forward. Having sex on three alternate days in that week before you expect it to happen should mean there is sperm in situ.</p>
      <p>It's also worth mentioning that Fertility Friend is an algorithm, and draws lines on charts where it thinks ovulation has happened, and a dashed line if it is less certain. These lines aren't always correct, and you can't beat a trained human eye to interpret it. It is worth running charts past someone who has been trained to interpret them (such as myself!) As part of my Reproflexology training I covered how to spot progesterone deficiencies, luteal phase defects and problems with ovulation in charts - and I am sure there are other fertility specialists around who could help in this regard. I think if I was temperature charting without having someone to run my charts past, it could have led to me being very stressed and confused.</p>
      <p class="bold">Really there is a lot to be gained from temperature charting, it can bring about an increased sense of control in those who have been trying to conceive for a while without knowing if there is an issue, or reassurance, if you find out your cycle is working well or improving.</p>
      <p>Just as an aside: there are also benefits to temperature charting if you are not trying to conceive, just in terms of knowing your cycle, your hormones and when you can expect to feel a certain way (for example, you may feel more energised around ovulation, tired before your period). I won't get into it here but Maisie Hill's book, Period Power is a fantastic insight if you are interested in living your best life according to your cycle.</p>
    </div>
  </div>
</template>

<script>
import temperatureCharting from '../assets/images/temperature-charting.jpg';
import temperatureChart1 from '../assets/images/fertility-friend-chart-1.jpg';
import temperatureChart2 from '../assets/images/fertility-friend-chart-2.jpg';

export default {
  data () {
    return {}
  }
}
</script>

<style scoped lang="less">
@import '../assets/css/less/responsive.less';

.content {
  > .subtitle {
    grid-column: 2/8;
    grid-row: 1/2;
    .screen-xs-max({
      grid-column: 1/9;
    });
    > h1 {
      margin: 0;
    }
    > p {
      margin-bottom: 0;
    }
  }
  > .title {
    grid-column: 2/8;
    grid-row: 2/3;
    .screen-xs-max({
      grid-column: 1/9;
      grid-row: 3/4;
    });
    > h2 {
      font-size: 2.2em;
    }
  }
  > h3 {
    grid-column: 2/5;
    grid-row: 3/4;
    margin: 0;
    .screen-xs-max({
      grid-column: 1/9;
      grid-row: 4/5;
    });
  }
  > .image-container {
    grid-row: 3/5;
    grid-column: 5/8;
    .screen-xs-max({
      grid-row: 2/3;
      grid-column: 1/9;
    });
    > .image {
      width: 100%;
      object-position: center center;
    }
    > p {
      margin-top: 20px;
      font-size: 0.9em;
    }
  }
  > .body-copy {
    grid-column: 2/5;
    grid-row: 4/5;
    .screen-xs-max({
      grid-column: 1/9;
      grid-row: 5/6;
    });
    > ul {
      padding-left: 20px;
      margin: 20px 0;
    }
    img {
      width: 100%;
    }
  }
}
</style>
