<template>
  <div class="content">
    <div class="title">
      <h1>maternity reflexology</h1>
    </div>
    <h3>Maternity reflexology supports women during the <strong>pregnancy and postnatal</strong> periods.</h3>
    <img class="image" src="/src/assets/images/beach-clouds.jpg" alt="Pregnancy/maternity reflexology" />
    <div class="box">
      <div>
        <p>*A research study showed reflexology in pregnancy significantly reduced pain during labour and reduced the length of the first stage (Valiani M et al - 2010 “Reviewing the effect of Reflexology on pain and outcomes of the labour of primiparous women” Iranian Journal of Nursing and Midwifery Research. 15 (Dec) p302-310)</p>
        <p>An earlier study showed first-time mums who received 10 reflexology treatments prior to birth had average labour times of five to six hours.  It was noted the group treated with reflexology needed far less pain relief during labour too.  (G. Motha and J. McGrath - 1992 “The effects of reflexology on labour outcome”)</p>
      </div>
    </div>
    <div class="body-copy">
      <h4>Pregnancy</h4>
      <h5>1-14 weeks</h5>
      <p>I am fully trained and insured to provide supportive and relaxing treatments during this time using Reproflexology techniques. Treatments last up to one hour (including consultation).</p>
      <h5>15-36 weeks</h5>
      <p>A lovely relaxing treatment is recommended, with a focus on helping to ease any pregnancy aches and pains such as acidity, digestive issues, heartburn, constipation, hip and back pain, sciatica, bleeding gums, insomnia and many more. Treatments last roughly an hour (including consultation).</p>
      <h5>37 weeks+</h5>
      <p>At this point we add to the above treatment with techniques to help prime you for a natural labour. Treatments last 1 hour 15 minutes (including consultation).</p>
      <h4>Postnatal</h4>
      <p>Reflexology supports recovery in the weeks following birth through:</p>
      <ul>
        <li>encouraging hormonal rebalance;</li>
        <li>boosting the immune system;</li>
        <li>lifting your mood;</li>
        <li>and regulating your sleep patterns.</li>
      </ul>
      <p>Being in a deeply relaxed state can also help boost the production of breast milk. Treatments last roughly an hour (including consultation) and it is fine to have your new baby with you and to stop the treatment to feed the baby or change a nappy etc - I allow extra time for this!</p>
    </div>
  </div>
</template>

<script>
import beachImg from '../assets/images/beach-clouds.jpg';

export default {
  data () {
    return {}
  }
}
</script>

<style scoped lang="less">
@import '../assets/css/less/responsive.less';

.content {
  grid-auto-flow: column;
  > .title {
    grid-column: 2/8;
    grid-row: 1/2;
    .screen-xs-max({
      grid-column: 1/9;
      grid-row: 2/3;
    });
  }
  > h3 {
    grid-column: 2/8;
    grid-row: 2/3;
    margin: 0;
    .screen-xs-max({
      grid-column: 1/9;
      grid-row: 3/4;
    });
  }
  > .image {
    grid-row: 3/4;
    .screen-xs-max({
      grid-row: initial;
    });
  }
  > .box {
    grid-column: 5/8;
    grid-row: 4/5;
    .screen-xs-max({
      grid-column: 1/9;
      grid-row: 4/5;
    });
    > div {
      font-size: 0.8em;
      padding: 15px;
      border: 1px solid #ccc;
    }
  }
  > .body-copy {
    grid-column: 2/5;
    grid-row: 3/20;
    > h4 {
      margin-top: 0;
    }
    .screen-xs-max({
      grid-column: 1/9;
      grid-row: 5/6;
    });
  }
}
</style>
