<template>
  <div class="content">
    <div class="title">
      <h1>pricing</h1>
    </div>
    <img class="image" src="/src/assets/images/yoga.jpg" alt="Yoga with a view" />
    <div class="box">
      <div>
        <h3>"Mummy to be" reflexology package:</h3>
        <h4>Duration varies - <strong>£200</strong></h4>
        <p>This package includes:</p>
        <ul>
          <li>4 x 1 hour reflexology treatments to be used between weeks 15 and 36</li>
          <li>1 x 1 hour 15 minutes late pregnancy treatment to be used from weeks 37+</li>
          <li>1 x 1 hour post-natal reflexology treatment to be used within the fourth trimester</li>
        </ul>
        <p>Separately these treatments would cost £250 so this package represents a £50 saving.  This would make a lovely gift for a mummy to be.</p>
      </div>
      <div>
        <h3>“6 for 5”, buy a package of 6 treatments for the cost of 5</h3>
        <h4><strong>£200</strong></h4>
      </div>
    </div>
    <div class="body-copy">
      <div class="prices">
        <span>1 hour <u>foot reflexology treatment</u> (inc. consultation)</span><span class="bold">£40</span>
        <span>1 hour 15 minute <u>foot reflexology treatment</u> (inc. consultation) allowing time for more specialised and tailored techniques such as anxiety relief or lymphatic drainage</span><span class="bold">£50</span>
        <span>Specialised <u>reproductive reflexology (Reproflexology)</u> consultation or treatment</span><span class="bold">£40</span>
        <span>1 hour 15 minute <u>stress melting foot and hand reflexology</u> treatment (inc. consultation)</span><span class="bold">£50</span>
        <span>1 hour <u>mid pregnancy reflexology</u> (inc. consultation)</span><span class="bold">£40</span>
        <span>1 hour 15 minute <u>late pregnancy (37+ weeks) foot reflexology</u> treatment (inc. consultation)</span><span class="bold">£50</span>
        <span>1 hour <u>post-natal reflexology</u> treatment (inc. consultation)</span><span class="bold">£40</span>
        <span>1 hour <u>reflexology for menopause treatment</u> (inc. consultation). This includes a routine designed to support women suffering with the main menopause symptoms: hot flushes, night sweats, insomnia, mood changes etc.</span><span class="bold">£40</span>
        <span>1 hour 15 minute <u>reflexology for menopause treatment</u> (inc. consultation). This session allows more time for if additional support is needed with symptoms like body aches or anxiety.</span><span class="bold">£50</span>
        <span>30+ minute <u>children's reflexology</u> (inc. consultation)</span><span class="bold">£30</span>
      </div>
      <p style="margin-top: 20px; text-decoration: underline;">Gift vouchers available for any amount.</p>
      <h3>Cancellations</h3>
      <p>While it is appreciated that emergencies can crop up at any time, glow reflexology is a small business so have to reserve the right to charge for the appointment if it is cancelled with less than 24 hours notice.</p>
    </div>
  </div>
</template>

<script>
import yogaImg from '../assets/images/yoga.jpg';

export default {
  data () {
    return {
    }
  }
}
</script>

<style scoped lang="less">
@import '../assets/css/less/responsive.less';

.content {
  > .title {
    grid-column: 2/8;
    grid-row: 1/2;
    .screen-xs-max({
      grid-column: 1/9;
      grid-row: 2/3;
    });
  }
  > .box {
    grid-column: 5/8;
    grid-row: 3/5;
    .screen-xs-max({
      grid-column: 1/9;
      grid-row: 4/5;
    });
    > div {
      font-size: 0.8em;
      padding: 15px;
      margin-bottom: 15px;
      border: 1px solid #ccc;
    }
  }
  > .body-copy {
    grid-column: 2/5;
    grid-row: 2/4;
    .screen-xs-max({
      grid-column: 1/9;
      grid-row: 3/4;
    });
    > .prices {
      display: grid;
      grid-template-columns: auto 50px;
      grid-gap: 15px;
    }
  }
  > .image {
    grid-row: 2/3;
    .screen-xs-max({
      grid-row: 1/2;
    });
  }
}
</style>
