<template>
  <div class="content">
    <div class="logo">
    	<span class="glow pacifico">glow</span>
    	<span class="reflexology lato">reflexology</span>
    </div>
    <div class="body-copy">
    	<h1 class="home lato">find your glow<br>with reflexology</h1>
      <div class="summary">
        <p>Offering treatments from my peaceful garden cabin and on a mobile basis within a 10 mile radius of Horsham.</p>
        <a href="https://www.aor.org.uk/" target="_blank"><img style="width: 200px;" class="image" src="/src/assets/images/excellence-in-reflexology-logo.png" alt="Association of Reflexologists" /></a>
        <a href="https://reproductivereflexologists.org/" target="_blank"><img style="height: 175px; margin-left: 20px;" class="image" src="/src/assets/images/arr-logo.jpg" alt="Association of Reproductive Reflexologists" /></a>
        <a href="/menopause"><img style="height: 175px; margin-left: 20px;" class="image" src="/src/assets/images/reflexology-for-menopause.png" alt="Reflexology for Menopause" /></a>
      </div>
    </div>
    <div class="latest-updates">
      <h3>latest updates</h3>
      <div v-for="(update, index) in updates" :key="index">
        <router-link :to="update.path" v-if="update.path && update.path.indexOf('/') === 0">
          <div :class="'alert ' + update.type" role="alert">
            <span class="badge">{{update.date}}</span> {{update.text}}
          </div>
        </router-link>
        <a :href="update.path" target="_blank" v-if="update.path && update.path.indexOf('http') === 0">
          <div :class="'alert ' + update.type" role="alert">
            <span class="badge">{{update.date}}</span> {{update.text}}
          </div>
        </a>
        <div v-if="!update.path" :class="'alert ' + update.type" role="alert">
          <span class="badge">{{update.date}}</span> {{update.text}}
        </div>
      </div>
    </div>
    <div class="bg"></div>
  </div>
</template>

<script>
import { store } from "../store.js";
import excellenceImg from '../assets/images/excellence-in-reflexology-logo.png';
import arrImg from '../assets/images/arr-logo.jpg';
import menopauseImg from '../assets/images/reflexology-for-menopause.png';

export default {
  data () {
    return {
      updates: [
        {
          type: 'alert-primary',
          path: null,
          date: '26 Apr 2023',
          text: 'Completed masterclass in plantar faciitis.'
        },
        {
          type: 'alert-secondary',
          path: '/menopause',
          date: '13 Dec 2022',
          text: 'Now qualified in Reflexology for Menopause'
        },
        {
          type: 'alert-secondary',
          path: '/blog/understanding-your-cycle-with-temperature-charting',
          date: '8 Jun 2022',
          text: 'New blog post: "Understanding your cycle with temperature charting"'
        }
      ]
    }
  },
  created: function(){
    store.state.scrolledBottom = true;
  }
}
</script>

<style scoped lang="less">
@import '../assets/css/less/responsive.less';
@import '../assets/css/less/palette.less';

.content {
  padding: 0 30px 50px;
  > .logo {
    grid-row: 1/2;
    grid-column: 1/7;
    justify-self: start;
    align-self: start;
    text-align: center;
    cursor: default;
    .screen-lg-min({
      margin-top: 50px;
      font-size: 4em;
    });
    .screen-xs-max({
      grid-column: 1/9;
      justify-self: center;
      align-self: center;
    });
    .lato {
      color: #fff;
    }
  }
  > .body-copy {
    grid-column: 1/6;
    grid-row: 1/2;
    text-align: left;
    margin-top: 100px;
    .screen-lg-min({
      margin-top: 160px;
      grid-column: 1/5;
    });
    color: #fff;
    .screen-sm({
      grid-column: 1/6;
    });
    .screen-xs-max({
      grid-column: 1/9;
      grid-row: 2/3;
      text-align: center;
    });
    > h1 {
      font-weight: 100;
      font-size: 5em;
      margin-bottom: 30px;
      line-height: 0.9;
      .screen-sm-max({ font-size: 3.3em; });
      .screen-xxs-max({ font-size: 2em; });
      .screen-md({ font-size: 4em; });
    }
    .link {
      width: auto;
      font-size: 1.3em;
      font-weight: 700;
      text-transform: uppercase;
      text-shadow: #333 0px 0px 20px;
    }
    .summary {
      font-size: 1.2em;
      text-align: left;
      font-weight: 300;
      .screen-xs-max({
        text-align: center;
      });
      > a {
        line-height: 9;
      }
    }
  }
  .latest-updates {
    grid-row: 1;
    grid-column: 7/9;
    width: auto;
    text-align: left;
    .screen-sm({
      grid-column: 6/9;
    });
    .screen-xs-max({
      grid-row: 4/5;
      grid-column: 1/9;
      text-align: center;
    });
    .alert {
      position: relative;
      font-weight: 400;
      text-align: left;
      padding: 15px 15px;
      margin-bottom: 20px;
      font-size: 0.7em;
      border-radius: 0;
      .screen-xs-max({
        font-size: 0.9em;
      });
      &.alert-primary {
        color: darken(@brown, 40%);
        background-color: fade(@orange, 90%);
        border: 1px solid @brown;
      }
      &.alert-secondary {
        color: darken(@brown, 40%);
        background-color: fade(#fff, 80%);
        border: 1px solid darken(@brown, 20%);
      }
      > .badge {
        border-radius: 0;
        position: absolute;
        right: 10px;
        top: -10px;
        color: darken(@brown, 40%);
        background-color: fade(#fff, 80%);
      }
    }
  }
  > .bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../assets/images/bg-cabin.jpg');
    background-size: cover;
    z-index: -1;
  }
}
</style>
