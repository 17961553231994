<template>
  <div class="content">
    <div class="title">
      <h1>menopause reflexology</h1>
    </div>
    <h3>
      <div><strong>Did you know?</strong></div>
      <div><strong>Stress</strong> can make menopausal symptoms worse.</div>
      <div><strong>Relaxation</strong> can ease symptoms.</div>
      <div><strong>Deep relaxation can help the body to heal and repair.</strong></div>
    </h3>
    <img class="image" src="/src/assets/images/surfer-stretching.jpg" alt="Reflexology for menopause" />
    <div class="body-copy">
      <p><b>Reflexology for menopause</b> provides this deep relaxation, focusing on the reflexes that most need it at this stage in life, with add ons to specifically target anxiety, aches and pains and hormonal imbalance.  Both one hour and one hour fifteen minute options are available, depending on your specific symptoms.</p>
      <h3>Key Menopause Research Findings:</h3>
      <h5>Reflexology is an effective therapy for sleep disorders and hot flushes in menopause. <sup>1</sup></h5>
      <h5>Reflexology can be effective in decreasing vasomotor problems and increasing quality of life in the menopausal period. <sup>2</sup></h5>
      <h5>The foot reflexology technique can be effective in reducing women’s depression during menopause. <sup>3</sup></h5>
      <p><sub>1. "The effects of reflexology on sleep disorder in menopausal women" - Maryam Asltoghiri, Zahra Ghodsi. Procedia - Social and Behavioral Sciences, Volume 31, 2012, Pages 242-246.</sub></p>
      <p><sub>2. "The effects of reflexology applied to women aged between 40 and 60 on vasomotor complaints and quality of life" - Gozuyesil E, Baser M. Complement Ther Clin Pract. 2016 Aug;24:78-85.</sub></p>
      <p><sub>3. "The effects of reflexology on depression during menopause: A randomised, controlled clinical trial" - F Mahdavipour et al. Complementary Therapies in Medicine, Volume 47, 2019.</sub></p>
    </div>
  </div>
</template>

<script>
import beachImg from '../assets/images/beach-clouds.jpg';

export default {
  data () {
    return {}
  }
}
</script>

<style scoped lang="less">
@import '../assets/css/less/responsive.less';

.content {
  grid-auto-flow: column;
  > .title {
    grid-column: 2/8;
    grid-row: 1/2;
    .screen-xs-max({
      grid-column: 1/9;
      grid-row: 2/3;
    });
  }
  > h3 {
    grid-column: 2/8;
    grid-row: 2/3;
    margin: 0;
    .screen-xs-max({
      grid-column: 1/9;
      grid-row: 3/4;
    });
    > div {
      line-height: 1.8;
    }
  }
  > .image {
    grid-row: 3/4;
    object-fit: contain;
    .screen-xs-max({
      grid-row: initial;
    });
  }
  > .box {
    grid-column: 5/8;
    grid-row: 4/5;
    .screen-xs-max({
      grid-column: 1/9;
      grid-row: 4/5;
    });
    > div {
      font-size: 0.8em;
      padding: 15px;
      border: 1px solid #ccc;
    }
  }
  > .body-copy {
    grid-column: 2/5;
    grid-row: 3/10;
    > h4 {
      margin-top: 0;
    }
    .screen-xs-max({
      grid-column: 1/9;
      grid-row: 5/6;
    });
  }
}
</style>
