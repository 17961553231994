import Vue from 'vue';
import VueRouter from 'vue-router';
import App from './App.vue';

import { store } from './store';

import Home from './views/home.vue';
import Reflexology from './views/reflexology.vue';
import Menopause from './views/menopause.vue';
import Reproductive from './views/reproductive.vue';
import Maternity from './views/maternity.vue';
import Pricing from './views/pricing.vue';
import About from './views/about.vue';
import Contact from './views/contact.vue';
import Shop from './views/shop.vue';
import TermsConditions from './views/terms-and-conditions.vue';
import DataProtectionPolicy from './views/data-protection-policy.vue';
import CodeOfEthics from './views/code-of-ethics.vue';
import Blog from './views/blog.vue';
import HowToGetMoreSleep from './views/how-to-get-more-sleep.vue';
import HowToAvoidFestiveFatigue from './views/how-to-avoid-festive-fatigue.vue';
import BenefitsOfPostnatalReflexology from './views/benefits-of-postnatal-reflexology.vue';
import NewsletterJanuary2021 from './views/newsletter-january-2021.vue';
import UnhelpfulThoughts from './views/jo-dalziel-unhelpful-thoughts.vue';
import TemperatureCharting from './views/understanding-your-cycle-with-temperature-charting.vue';
import Covid19 from './views/covid-19.vue';

Vue.use(VueRouter);

// title = 50 characters
// description = 155 characters

const routes = [
  { path: '/', component: Home, meta: {
    title: 'Reflexology Horsham, garden treatment room/mobile',
    metaTags: [
      { property: 'og:type', content: 'website' },
      { property: 'og:title', content: 'Reflexology Horsham, garden treatment room/mobile' },
      { name: 'description', content: 'Find your glow. Book a treatment with an MAR, MARR fully insured reflexologist. Reproductive (reproflexology), pregnancy & postnatal treatments available.' },
      { property: 'og:description', content: 'Find your glow. Book a treatment with an MAR, MARR fully insured reflexologist. Reproductive (reproflexology), pregnancy & postnatal treatments available.' },
      { property: 'og:url', content: 'https://glowreflexology.co.uk' },
      { property: 'og:site_name', content: 'glow reflexology' },
      { property: 'og:locale', content: 'en_GB' }
    ]
  }},
  { path: '/reflexology', component: Reflexology, meta: {
    title: 'About reflexology',
    metaTags: [
      { property: 'og:type', content: 'website' },
      { property: 'og:title', content: 'About Reflexology' },
      { name: 'description', content: 'The ancient practice of reflexology was developed from the premise that there are reflexes in your feet that correspond with different parts of your body.' },
      { property: 'og:description', content: 'The ancient practice of reflexology was developed from the premise that there are reflexes in your feet that correspond with different parts of your body.' },
      { property: 'og:url', content: 'https://glowreflexology.co.uk/reflexology' },
      { property: 'og:site_name', content: 'glow reflexology' },
      { property: 'og:locale', content: 'en_GB' }
    ]
  }},
  { path: '/menopause', component: Menopause, meta: {
    title: 'Menopause reflexology',
    metaTags: [
      { property: 'og:type', content: 'website' },
      { property: 'og:title', content: 'Menopause reflexology' },
      { name: 'description', content: 'Reflexology for menopause provides this deep relaxation, focusing on the reflexes that most need it at this stage in life, with add ons to specifically target anxiety, aches and pains and hormonal imbalance.' },
      { property: 'og:description', content: 'Reflexology for menopause provides this deep relaxation, focusing on the reflexes that most need it at this stage in life, with add ons to specifically target anxiety, aches and pains and hormonal imbalance.' },
      { property: 'og:url', content: 'https://glowreflexology.co.uk/menopause' },
      { property: 'og:site_name', content: 'glow reflexology' },
      { property: 'og:locale', content: 'en_GB' }
    ]
  }},
  { path: '/reproductive', component: Reproductive, meta: {
    title: 'Reproductive reflexology',
    metaTags: [
      { property: 'og:type', content: 'website' },
      { property: 'og:title', content: 'Reproductive reflexology' },
      { name: 'description', content: 'Try a more natural and holistic approach to improving your reproductive health, and in turn, ability to conceive.' },
      { property: 'og:description', content: 'Try a more natural and holistic approach to improving your reproductive health, and in turn, ability to conceive.' },
      { property: 'og:url', content: 'https://glowreflexology.co.uk/reproductive' },
      { property: 'og:site_name', content: 'glow reflexology' },
      { property: 'og:locale', content: 'en_GB' }
    ]
  }},
  { path: '/maternity', component: Maternity, meta: {
    title: 'Maternity reflexology',
    metaTags: [
      { property: 'og:type', content: 'website' },
      { property: 'og:title', content: 'Maternity reflexology' },
      { name: 'description', content: 'Maternity reflexology is an area of reflexology aimed specifically at couples during pre-conception, pregnancy and postnatal periods.' },
      { property: 'og:description', content: 'Maternity reflexology is an area of reflexology aimed specifically at couples during pre-conception, pregnancy and postnatal periods.' },
      { property: 'og:url', content: 'https://glowreflexology.co.uk/maternity' },
      { property: 'og:site_name', content: 'glow reflexology' },
      { property: 'og:locale', content: 'en_GB' }
    ]
  }},
  { path: '/pricing', component: Pricing, meta: {
    title: 'Pricing',
    metaTags: [
      { property: 'og:type', content: 'website' },
      { property: 'og:title', content: 'Pricing' },
      { name: 'description', content: 'Home visit in Horsham and surrounding areas, £35' },
      { property: 'og:description', content: 'Home visit in Horsham and surrounding areas, £35' },
      { property: 'og:url', content: 'https://glowreflexology.co.uk/pricing' },
      { property: 'og:site_name', content: 'glow reflexology' },
      { property: 'og:locale', content: 'en_GB' }
    ]
  }},
  { path: '/about', component: About, meta: {
    title: 'About glow',
    metaTags: [
      { property: 'og:type', content: 'website' },
      { property: 'og:title', content: 'About glow' },
      { name: 'description', content: 'I operate on a mobile basis within a 10 mile radius of Horsham.' },
      { property: 'og:description', content: 'I operate on a mobile basis within a 10 mile radius of Horsham.' },
      { property: 'og:url', content: 'https://glowreflexology.co.uk/about' },
      { property: 'og:site_name', content: 'glow reflexology' },
      { property: 'og:locale', content: 'en_GB' }
    ]
  }},
  { path: '/shop', component: Shop, meta: {
    title: 'Shop',
    metaTags: [
      { name: 'robots', content: 'noindex, nofollow' }
    ]
  }},
  { path: '/contact', component: Contact, meta: {
    title: 'Contact glow reflexology',
    metaTags: [
      { property: 'og:type', content: 'website' },
      { property: 'og:title', content: 'Contact glow reflexology' },
      { name: 'description', content: 'To make a booking, an enquiry, or just to find out a bit more about reflexology, please feel free to get in touch.' },
      { property: 'og:description', content: 'To make a booking, an enquiry, or just to find out a bit more about reflexology, please feel free to get in touch.' },
      { property: 'og:url', content: 'https://glowreflexology.co.uk/contact' },
      { property: 'og:site_name', content: 'glow reflexology' },
      { property: 'og:locale', content: 'en_GB' }
    ]
  }},
  { path: '/terms-and-conditions', component: TermsConditions, meta: {
    title: 'terms & conditions',
    metaTags: [
      { name: 'robots', content: 'noindex, nofollow' }
    ]
  }},
  { path: '/data-protection-policy', component: DataProtectionPolicy, meta: {
    title: 'data protection policy - glow reflexology',
    metaTags: [
      { name: 'robots', content: 'noindex, nofollow' }
    ]
  }},
  { path: '/code-of-ethics', component: CodeOfEthics, meta: {
    title: 'code of ethics - glow reflexology',
    metaTags: [
      { name: 'robots', content: 'noindex, nofollow' }
    ]
  }},
  { path: '/blog', component: Blog, meta: {
    title: 'blog - glow reflexology',
    metaTags: [
      { name: 'robots', content: 'noindex, nofollow' }
    ]
  }},
  { path: '/blog/how-to-get-more-sleep', component: HowToGetMoreSleep, meta: {
    title: 'How to...get more sleep',
    metaTags: [
      { property: 'og:type', content: 'website' },
      { property: 'og:title', content: 'How to...get more sleep' },
      { name: 'description', content: 'We all know that a lack of sleep can make our days much harder than they would otherwise be.' },
      { property: 'og:description', content: 'We all know that a lack of sleep can make our days much harder than they would otherwise be.' },
      { property: 'og:url', content: 'https://glowreflexology.co.uk/blog/how-to-get-more-sleep' },
      { property: 'og:site_name', content: 'glow reflexology' },
      { property: 'og:locale', content: 'en_GB' }
    ]
  }},
  { path: '/blog/how-to-avoid-festive-fatigue', component: HowToAvoidFestiveFatigue, meta: {
    title: 'How to...avoid festive fatigue',
    metaTags: [
      { property: 'og:type', content: 'website' },
      { property: 'og:title', content: 'How to...avoid festive fatigue' },
      { name: 'description', content: 'A list of my top tips to get you through the challenges & stress of the festive season.' },
      { property: 'og:description', content: 'A list of my top tips to get you through the challenges & stress of the festive season.' },
      { property: 'og:url', content: 'https://glowreflexology.co.uk/blog/how-to-avoid-festive-fatigue' },
      { property: 'og:site_name', content: 'glow reflexology' },
      { property: 'og:locale', content: 'en_GB' }
    ]
  }},
  { path: '/blog/benefits-of-postnatal-reflexology', component: BenefitsOfPostnatalReflexology, meta: {
    title: 'Benefits of postnatal reflexology',
    metaTags: [
      { property: 'og:type', content: 'website' },
      { property: 'og:title', content: 'Benefits of postnatal reflexology' },
      { name: 'description', content: 'Reflexology helps encourage recovery at a time when new mums are often overlooked.' },
      { property: 'og:description', content: 'Reflexology helps encourage recovery at a time when new mums are often overlooked.' },
      { property: 'og:url', content: 'https://glowreflexology.co.uk/blog/benefits-of-postnatal-reflexology' },
      { property: 'og:site_name', content: 'glow reflexology' },
      { property: 'og:locale', content: 'en_GB' }
    ]
  }},
  { path: '/blog/understanding-your-cycle-with-temperature-charting', component: TemperatureCharting, meta: {
    title: 'Understanding your cycle with temperature charting',
    metaTags: [
      { property: 'og:type', content: 'website' },
      { property: 'og:title', content: 'Understanding your cycle with temperature charting' },
      { name: 'description', content: 'When trying to conceive, basal body temperature charting can help you understand if and when you are ovulating and when your "fertile window" is.' },
      { property: 'og:description', content: 'When trying to conceive, basal body temperature charting can help you understand if and when you are ovulating and when your "fertile window" is.' },
      { property: 'og:url', content: 'https://glowreflexology.co.uk/blog/understanding-your-cycle-with-temperature-charting' },
      { property: 'og:site_name', content: 'glow reflexology' },
      { property: 'og:locale', content: 'en_GB' }
    ]
  }},
  { path: '/blog/newsletter-january-2021', component: NewsletterJanuary2021, meta: {
    title: 'Newsletter January 2021',
    metaTags: [
      { property: 'og:type', content: 'website' },
      { property: 'og:title', content: 'Newsletter January 2021' },
      { name: 'description', content: 'People are recognising the importance of self-care at a time when they are working under difficult conditions and facing high levels of anxiety.' },
      { property: 'og:description', content: 'People are recognising the importance of self-care at a time when they are working under difficult conditions and facing high levels of anxiety.' },
      { property: 'og:url', content: 'https://glowreflexology.co.uk/blog/newsletter-january-2021' },
      { property: 'og:site_name', content: 'glow reflexology' },
      { property: 'og:locale', content: 'en_GB' }
    ]
  }},
  { path: '/blog/jo-dalziel-unhelpful-thoughts', component: UnhelpfulThoughts, meta: {
    title: 'Unhelpful Thoughts by Jo Dalziel',
    metaTags: [
      { name: 'robots', content: 'noindex, nofollow' }
    ]
  }},
  { path: '/covid-19', component: Covid19, meta: {
    title: 'COVID-19: safely re-opening my practice',
    metaTags: [
      { name: 'robots', content: 'noindex, nofollow' }
    ]
  }}
];

export const router = new VueRouter({
  routes,
  mode: 'history'
});

var processForSEO = function(to, from, next){
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);
  const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if(nearestWithTitle) document.title = nearestWithTitle.meta.title;

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

  // Skip rendering meta tags if there are none.
  if(!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags.map(tagDef => {
    const tag = document.createElement('meta');

    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key]);
    });

    // We use this to track which meta tags we create, so we don't interfere with other ones.
    tag.setAttribute('data-vue-router-controlled', '');

    return tag;
  })
  // Add the meta tags to the document head.
  .forEach(tag => document.head.appendChild(tag));

  next();
};

// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
  processForSEO(to, from, next);
});

new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App),
  mounted () {
    var that = this;

    document.dispatchEvent(new Event('render-event'));
  }
});
