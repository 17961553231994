<template>
  <div class="content">
    <div class="title">
      <h1>reproductive</h1>
    </div>
    <h3>Offering tailored treatments to support those trying to conceive as well as those wanting to better manage the symptoms of reproductive conditions.</h3>
    <img class="image" src="/src/assets/images/reproductive.jpg" alt="Reproductive" />
    <div class="box">
      <div>
        <h4 style="margin: 0 auto 15px; font-weight: 700;">I have packages to make this more affordable.</h4>
        <hr>
        <div>
          <strong>6 for 5</strong>
          <p>Buy a package of six 1 hour treatments (inc. consultation) for the cost of 5 @ £200. This can be split between a couple or household however they please.</p>
        </div>
        <hr>
        <div>
          <strong>Couples Reproflexology</strong>
          <p style="margin-bottom: 0;">Total appointment time (inc. consultation) is 90 minutes, @ £60. Both sides of the couple receive shorter, more focused treatments.</p>
        </div>
      </div>
    </div>
    <div class="body-copy">
      <p>I am trained in Reproflexology and am a proud member of the Association of Reproductive Reflexologists (ARR).</p>
      <p>Reproflexology for pre-conception is excellent for anyone who is considering getting pregnant, whether you have a known condition or not, it's a great way to ensure that your body is at its best and ready for the coming months. I am also trained to support couples or individuals through medicated cycles (e.g. Clomid) or assisted conception (IUI, IVF, ICSI). But you don't have to be trying to conceive to benefit from Reproflexology, it can help with managing symptoms of endometriosis, PCOS, fibroids, ovulation problems, PMS, heavy or light flow etc.</p>
      <p>Male factor issues contribute to around half of all cases of fertility issues in couples. Reproflexology can be a great support for men with issues with their sperm quality (e.g. morphology or deficiency) or with specific conditions such as varicocele or prostatitis.</p>
      <p>Reproflexology can really help to boost the chances of a successful pregnancy, and research has been undertaken to support this (<a href="https://reproductivereflexologists.org/reflexology-can-it-really-help-you-to-get-pregnant/">more information here</a>).  Many private fertility clinics, GPs and midwives recommend Reproflexology.</p>
      <h5>What does it involve?</h5>
      <p>I start by sending you an in depth consultation form so that I can understand your medical history and where relevant, your fertility journey so far. This then forms the basis for a one hour consultation during which we will talk through everything in your form and the results of any investigations.  We'll also go through the Reproflexology process and I can advise you on lifestyle factors that might improve your condition or chances of conception, as well as any other holistic methods that may benefit. Having an in-depth understanding of everything you have been through and are going through really helps me to plan my treatments so they provide the most benefit. </p>
      <p>We will then agree on a treatment plan that is bespoke and achievable for you. </p>
      <p>For couples it's beneficial for me to meet with both sides, though understandably this is not always achievable. </p>
      <p>Currently I am undertaking these consultations via Zoom.</p>
      <p>For most female clients, I recommend weekly treatments for at least four weeks so that I can work with their menstrual cycle to optimise each part of it. It may then be possible to reduce this down to bi-monthly treatments but of course each client is different and we work around availability and what is achievable.  If you are going through assisted conception, I am fully trained and insured to provide treatments for each week of the process including the two week wait, the first trimester and the remainder of your pregnancy.</p>
      <p>For male clients, it's beneficial to have treatments for 8-12 weeks, targeting treatments to known conditions and aiming to improve stress levels. It's recommended to have semen analysis tests both before commencing treatment and after three months so that we can observe any changes - I can also help you to arrange these.</p>
      <p>Treatments last up to one hour and include relaxation work as well as some targeted non-reproductive/hormonal work if it would be of benefit.</p>
      <p>I am also available in between appointments for WhatsApp or email support with things like cycle monitoring, hand reflexology homework and additional lifestyle advice.</p>
      <p><router-link to="/contact"><span class="link">Contact</span></router-link> me in confidence to find out more.</p>
      <img class="image" src="/src/assets/images/arr-logo.jpg" alt="Association of Reproductive Reflexologists" />
    </div>
  </div>
</template>

<script>
import reproductiveImg from '../assets/images/reproductive.jpg';
import arrImg from '../assets/images/arr-logo.jpg';

export default {
  data () {
    return {}
  }
}
</script>

<style scoped lang="less">
@import '../assets/css/less/responsive.less';

.content {
  grid-auto-flow: column;
  > .title {
    grid-column: 2/8;
    grid-row: 1/2;
    .screen-xs-max({
      grid-column: 1/9;
      grid-row: 2/3;
    });
  }
  > h3 {
    grid-column: 2/8;
    grid-row: 2/3;
    margin: 0;
    .screen-xs-max({
      grid-column: 1/9;
      grid-row: 3/4;
    });
  }
  > .image {
    object-position: center center;
    grid-row: 3/4;
    .screen-xs-max({
      grid-row: initial;
    });
  }
  > .box {
    grid-column: 5/8;
    grid-row: 4/5;
    .screen-xs-max({
      grid-column: 1/9;
      grid-row: 4/5;
    });
    > div {
      font-size: 0.8em;
      padding: 15px;
      border: 1px solid #ccc;
    }
  }
  > .body-copy {
    grid-column: 2/5;
    grid-row: 3/20;
    .screen-xs-max({
      grid-column: 1/9;
      grid-row: 5/6;
    });
    > ul {
      padding-left: 20px;
      margin: 20px 0;
    }
  }
}
</style>
