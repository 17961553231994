<template>
  <div class="content">
    <div class="title">
      <h1>shop</h1>
    </div>
    <div class="body-copy">
      <p><a href="https://www.tropicskincare.com/emilybryan" target="_blank">Tropic</a> - shop award winning, natural beauty and skincare</p>
      <img class="image" src="/src/assets/images/tropic-skincare-products.jpg" alt="Tropic Skincare Products" />
      <hr>
      <p><a href="https://www.etsy.com/uk/shop/ImmyandBooprints?section_id=28939523" target="_blank">Immy and Boo (Wales)</a> - for the beautiful lavender eye pillows used in my treatments</p>
      <img class="image" src="/src/assets/images/immy-and-boo-eye-pillows.jpg" alt="Immy and Boo Eye Pillows" />
    </div>
  </div>
</template>

<script>
import tropicProducts from '../assets/images/tropic-skincare-products.jpg';
import immyAndBoo from '../assets/images/immy-and-boo-eye-pillows.jpg';

export default {
  data () {
    return {}
  }
}
</script>

<style scoped lang="less">
@import '../assets/css/less/responsive.less';

.content {
  > .title {
    grid-column: 3/7;
    .screen-xs-max({
      grid-column: 1/9;
    });
  }
  > .details {
    grid-column: 3/7;
    .screen-xs-max({
      grid-column: 1/9;
    });
  }
  > .body-copy {
    grid-column: 3/7;
    .screen-xs-max({
      grid-column: 1/9;
    });
    > .image {
      width: 60%;
      .screen-xs-max({
        width: 100%;
      });
    }
    > hr {
      margin: 2em 0;
    }
  }
  hr {
    margin: 5px 0;
  }
}
</style>
