<template>
  <div class="content">
    <div class="title">
      <h1>about glow</h1>
    </div>
    <div class="images">
      <img class="image" src="/src/assets/images/emily-bryan-reflexologist.jpg" alt="Emily Bryan, Mobile reflexologist, Horsham" />
    </div>
    <div class="body-copy">
      <h2>Emily Bryan MAR MARR MSc (hons)</h2>
      <p>After years spent working in London and Melbourne in jobs that often left me
        feeling frazzled and unable to sleep, and turning to reflexology in my first pregnancy -
        I was amazed by the effect it had on me. I felt able to sleep much more deeply than
        I had in ages. It affected me so much that I hatched a plan. I started studying and
        practicing after I had my second baby, qualified with a Level 3 Diploma in Reflexology
        from Brighton Holistics and became a member of the Association of Reflexologists.</p>
        <p>I live in Horsham with my husband, two young boys and fur baby. These guys have taught me the importance of self-care - you can't pour from an empty cup!  I am married to an Aussie, and we are normally planning our next big (or little) trip.  I love a festival, beach, music, yoga and running. Being in nature makes me happy.</p>
        <p style="margin-top: 50px; text-align: center;">
        <img class="image em-sig" src="/src/assets/images/em-sig.png" alt="Emily Bryan, MAR Reflexologist" />
        <img class="image" src="/src/assets/images/excellence-in-reflexology-logo.png" width="200px" alt="Association of Reflexologists" />
      </p>
    </div>
    <div class="full-width">
      <h2>what to expect</h2>
      <h3>Offering treatments from my peaceful garden treatment room in central Horsham, or from the comfort of your own home, at no extra cost.</h3>
      <p>Everything required is provided (chair, towels, music etc).</p>
      <p>A choice of reflexology balm and music is available as everyone relaxes differently. An optional scented eye pillow or weighted blanket are on hand to maximise relaxation.</p>
      <p>The session starts with a consultation which helps me to understand any past or current health or lifestyle issues so that the treatment can be tailored to you.</p>
      <p>Clients are encouraged to wear comfy clothes during a treatment (pyjamas are totally fine!)  You only need to remove your socks and shoes, and wear trousers that are able to be rolled up a bit with ease.</p>
      <p>I start with a warm up massage then undertake a full treatment which involves a range of different techniques on your feet and lower legs with a level of pressure that suits you. I then finish with a warm down massage.  During this time feel free to completely zone out, fall asleep - snoring is a good sign that it is working!</p>
      <img style="margin-top: 2em;" class="image" src="/src/assets/images/the-cabin.jpg" alt="Garden treatment room" />
    </div>
  </div>
</template>

<script>
import emilyBryanImg from '../assets/images/emily-bryan-reflexologist.jpg';
import excellenceImg from '../assets/images/excellence-in-reflexology-logo.png';
import emSigImg from '../assets/images/em-sig.png';
import towelsCandlesImg from '../assets/images/the-cabin.jpg';

export default {
  data () {
    return {}
  }
}
</script>

<style scoped lang="less">
@import '../assets/css/less/responsive.less';

.content {
  > .title {
    grid-column: 2/8;
    grid-row: 1/2;
    .screen-xs-max({
      grid-column: 1/9;
      grid-row: 2/3;
    });
  }
  > h3 {
    grid-column: 2/5;
    grid-row: 2/3;
    margin: 0;
    .screen-xs-max({
      grid-column: 1/9;
      grid-row: 3/4;
    });
  }
  > .body-copy {
    grid-column: 2/5;
    grid-row: 2;
    .screen-xs-max({
      grid-column: 1/9;
      grid-row: 4;
    });
    .em-sig {
      margin: 0 auto;
      width: 150px;
      margin-right: 80px;
    }
  }
  .full-width {
    grid-column: 2/8;
    .screen-xs-max({
      grid-column: 1/9;
    });
    .screen-xs-max({
      grid-row: 5;
    });
  }
  > .images {
    grid-column: 5/8;
    .screen-xs-max({
      grid-column: 1/9;
    });
    > .image {
      height: auto;
      width: 100%;
      grid-auto-flow: row;
      margin-bottom: 20px;
    }
  }
}
</style>
