<template>
  <div class="terms-and-conditions content">
    <div class="title">
      <h3>terms &amp; conditions</h3>
    </div>
    <div class="body-copy">
      <p>I am a full member of the Association of Reflexologists (AoR). Being a full member of the AoR demonstrates that I have a nationally recognised Diploma in Reflexology.</p>
      <p><strong>As an AoR member:</strong></p>
      <p>I am bound by the AoR Code of Practice and Ethics.</p>
      <p>I follow the AoR Good Practice Policy and hygiene guidance to ensure client safety.</p>
      <p>I will always be adequately insured for medical malpractice/ professional indemnity requirements. The industry standard is in excess of £5 million cover.</p>
      <p>I comply with the AoR Continuing Professional Development requirements which ensures my practice is kept up to date.</p>
      <p>I am compliant with GDPR data protection, please see my separate privacy policy. Your information will remain confidential at all times.</p>
      <p>As a professional and highly qualified reflexologist with MAR status, I will provide you with the appropriate bespoke treatment and support.</p>
      <p>I keep data electronically so I am registered with the Information Commissioner's Office.</p>
      <p><strong>My Personal Business Practice Policies:</strong></p>
      <ol>
        <li>
          The use of face coverings
          <ul style="margin-bottom: 25px;">
            <li>I will always wear a face covering for every client for the foreseeable future.</li>
            <li>I chose to use discretion regarding the wearing of face coverings.</li>
          </ul>
        </li>
        <li>
          Appointments
          <ul style="margin-bottom: 25px;">
            <li>I request that if you have a cough, a fever or loss of taste and smell or have been in touch with anyone with these symptoms, please contact me as we may need to postpone your appointment.</li>
            <li>In return, I will apply these high standards to myself. Should I feel unwell, unable to treat or it is inadvisable for me to treat, I will explain the situation to you as soon as possible before your treatment.</li>
          </ul>
        </li>
        <li>
          Fees
          <ul style="margin-bottom: 25px;">
            <li>My fees are either payable in advance as part of a treatment package or in full at the time of treatment.</li>
            <li>I reserve the right to choose to use discretion regarding the application of any discounts.</li>
          </ul>
        </li>
        <li>
          Cancellation Policy
          <ul style="margin-bottom: 25px;">
            <li>I have a 24 hour cancellation policy.  As you are aware, my business is dependent on the number of clients I can see in my clinic hours.  Therefore if you are unable to make a previously arranged appointment, I request that you provide me with at least 24 hours notice.  This will allow me the option to re book the time slot with someone on my waiting list.</li>
            <li>Less than 24 hours notice may result in a fair-use fee of 100% of my usual treatment cost. This fee will be required to be settled prior to any future bookings.</li>
          </ul>
        </li>
        <li>
          Treatment packages
          <ul style="margin-bottom: 25px;">
            <li>I offer the option to book a package of treatments. These are provided at a reduced price as they are paid for in advance.</li>
            <li>These treatments can be booked during my normal working hours with no restrictions.</li>
            <li>Where an appointment is booked and missed, my cancellation policy above will apply and an amount will be deducted from those remaining.</li>
            <li>These treatments are paid in advance and are fully flexible with 24 hours notice.</li>
            <li>These treatments are not required to be used within a specific time frame.</li>
          </ul>
        </li>
        <li>
          Vouchers
          <ul style="margin-bottom: 25px;">
            <li>I supply the option to buy vouchers for treatments. These may be given as gifts.</li>
            <li>These treatments can be booked during my normal working hours with no restrictions.</li>
            <li>Where an appointment is booked and missed, my cancellation policy above will apply.</li>
            <li>These treatments are paid in advance and are fully flexible with 24 hours notice.</li>
            <li>These vouchers are non refundable.</li>
          </ul>
        </li>
        <li>
          Use of background music
          <ul style="margin-bottom: 25px;">
            <li>I only play Royalty free music within my business and therefore I am not required to have The Music Licence. Unless you are in your own home, I am unable to play your preferred music selection.</li>
          </ul>
        </li>
      </ol>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {}
  }
}
</script>

<style scoped lang="less">
@import '../assets/css/less/responsive.less';

.content {
  > .title {
    grid-column: 2/8;
    .screen-xs-max({
      grid-column: 1/9;
    });
  }
  > .body-copy {
    grid-column: 2/8;
    .screen-xs-max({
      grid-column: 1/9;
    });
  }
}
</style>
